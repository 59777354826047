import Script from 'next/script'
import { Persistor } from 'redux-persist/es/types'

import Toasts from '@/components/Toasts/Toasts'
import { useLoadAds } from '@/contexts/ads'

import Footer from './Footer/Footer'
import Navbar from './Navbar/Navbar'

type Props = {
  children: React.ReactNode
  persistor: Persistor
}

const Layout = ({ children, persistor }: Props) => {
  const loadAds = useLoadAds()
  return (
    <>
      <header>
        <Navbar persistor={persistor} />
        {loadAds === true && (
          <>
            <Script
              async
              data-cfasync='false'
              data-noptimize='1'
              src='//scripts.mediavine.com/tags/edhrec.js'
              type='text/javascript'
            />
            <Script async src='https://ssl.cdne.cpmstar.com/cached/js/lib.js' type='text/javascript' />
            <Script
              async
              data-property-id='4cdcf60c-10c5-4d3e-8db9-a8146a2e2f4f'
              id='bigcrunchtag'
              src='https://lh.bigcrunch.com/main.js'
            />
          </>
        )}
        <Script
          async
          defer
          src='https://ats-wrapper.privacymanager.io/ats-modules/51bf34e9-328a-43ce-990f-1cb019d12b3e/ats.js'
        />
      </header>
      {children}
      <Toasts />
      <Footer />
    </>
  )
}

export default Layout
