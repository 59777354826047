import classNames from 'classnames'
import Link from 'next/link'
import Card from 'react-bootstrap/Card'
import YouTube from 'react-youtube'

import { ArticleType } from '@//types/home'

import styles from './ContentPreview.module.scss'

type Props = {
  content: ArticleType
  videoOnly?: boolean
}

const ContentPreview = ({ videoOnly, content }: Props) => {
  const { date, link, title, youtube } = content
  return (
    <div
      className={classNames(styles.container, {
        [styles.podcast]: videoOnly,
      })}
    >
      <Card className={classNames('shadow-sm', styles.card)}>
        <div className={styles.youtube}>
          <YouTube videoId={youtube} />
        </div>
        {!videoOnly && (
          <div className={styles.body}>
            <div className={styles.date}>{date}</div>
            <h4 className={styles.heading}>
              <Link href={link}>{title}</Link>
            </h4>
          </div>
        )}
      </Card>
    </div>
  )
}

export default ContentPreview
