import '@/styles/globals.scss'
import '@/utils/removeChildFix'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'
import { AppContext, AppProps } from 'next/app'
import NextNProgress from 'nextjs-progressbar'
import { useMemo } from 'react'
import { CookiesProvider, Cookies } from 'react-cookie'
import { Provider } from 'react-redux'

import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary'
import FirebaseAuth from '@/components/FirebaseAuth/FirebaseAuth'
import GoogleAnalytics from '@/components/GoogleAnalytics/GoogleAnalytics'
import Layout from '@/components/Layout/Layout'
import RegionDetector from '@/components/RegionDetector/RegionDetector'
import ThemeProvider from '@/components/ThemeProvider/ThemeProvider'
import { AdsProvider } from '@/contexts/ads'
import { makeStore } from '@/store/store'

config.autoAddCss = false

const MyApp = ({ Component, cookies, pageProps }: AppProps & { cookies: string }) => {
  const isBrowser = typeof window !== 'undefined'
  const { store, persistor } = useMemo(() => makeStore(cookies), [])

  return (
    <ErrorBoundary>
      <GoogleAnalytics />
      <NextNProgress />
      <CookiesProvider cookies={isBrowser ? undefined : new Cookies(cookies)}>
        <ThemeProvider>
          <Provider store={store}>
            <RegionDetector />
            <AdsProvider>
              <Layout persistor={persistor}>
                <FirebaseAuth />
                <Component {...pageProps} />
              </Layout>
            </AdsProvider>
          </Provider>
        </ThemeProvider>
      </CookiesProvider>
    </ErrorBoundary>
  )
}

MyApp.getInitialProps = async (context: AppContext): Promise<{ cookies?: string }> => ({
  cookies: context.ctx.req?.headers.cookie,
})

export default MyApp
