import classNames from 'classnames'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { v4 } from 'uuid'

import OutboundLink from '@/components/OutboundLink/OutboundLink'
import { useAppSelector } from '@/store/hooks'
import { PriceMapType } from '@/types/card'
import { tcgplayerLink } from '@/utils/helpers'

import styles from './CardPrices.module.scss'

type TCGPContainerProps = {
  children: any
  saleEndTime: string
  tcgplayerSale: number
}

const TCGPContainer = ({ children, saleEndTime, tcgplayerSale }: TCGPContainerProps) =>
  tcgplayerSale > 0 ? (
    <OverlayTrigger
      overlay={
        <Tooltip id='button-tooltip'>
          <div>On Sale Until</div>
          <div>{saleEndTime}</div>
        </Tooltip>
      }
      placement='top'
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  )

type Props = {
  prices: PriceMapType
}

const CardPrices = ({ prices }: Props) => {
  const { tcgplayerKickback, tcgplayerSale } = useAppSelector((state) => state.misc)
  const priceSources = useAppSelector((state) => state.user.priceSources)

  const priceLinks: Partial<Record<keyof PriceMapType, () => React.ReactNode>> = {
    cardhoarder: () => (
      <OutboundLink key={v4()} title='Buy at Cardhoarder' url={prices.cardhoarder.url}>
        <div className={styles.price}>
          <span>{`${prices.cardhoarder.price} TIX`}</span>
        </div>
      </OutboundLink>
    ),
    cardkingdom: () => (
      <OutboundLink
        key={v4()}
        title='Buy at Card Kingdom'
        url={`${prices.cardkingdom.url}&partner_args=edhrec,card`}
        utm_medium='affiliate'
      >
        <div className={styles.price}>
          <svg
            height='12'
            version='1.1'
            viewBox='0 0 3.7041667 3.1750001'
            width='14'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g transform='translate(0 -293.82)'>
              <path d='m0 293.82v1.1592h0.52042v2.0158h2.6633v-2.0158h0.52042v-1.1592h-0.57636v1.0908h-0.49196v-1.084h-0.54839v1.084h-0.47074v-1.084h-0.54839v1.084h-0.49196v-1.0908z' />
            </g>
          </svg>
          <span>{`$${prices.cardkingdom.price.toFixed(2)}`}</span>
        </div>
      </OutboundLink>
    ),
    cardmarket: () => (
      <OutboundLink
        key={v4()}
        title='Buy at Cardmarket'
        url={`${prices.cardmarket.url}&utm_source=edhrec&utm_medium=text&utm_campaign=card_prices`}
        utm_medium='text'
      >
        <div className={styles.price}>
          <span>
            {`${
              typeof prices.cardmarket.price === 'number' ? prices.cardmarket.price.toFixed(2) : prices.cardmarket.price
            } €`.replace('.', ',')}
          </span>
        </div>
      </OutboundLink>
    ),
    face2face: () => {
      return (
        <OutboundLink key={v4()} title='Buy at Face to Face Games' url={prices.face2face.url} utm_medium='affiliate'>
          <div className={styles.price}>
            <svg
              enableBackground='new 0 0 864 1350.73'
              version='1.1'
              viewBox='0 0 864 1350.7'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g id='XMLID_986_'>
                <path
                  id='XMLID_991_'
                  className='st0'
                  d='M864,796.46H745.11c0,170.19-159.65,291.87-257.24,331.73v146.56   C617.34,1225.6,864,1043.43,864,796.46z'
                />
                <path
                  id='XMLID_990_'
                  className='st0'
                  d='M119.2,796.46H0.29c0,246.6,247.19,428.61,376.15,478.09v-145.82   C276.98,1089.6,119.2,968.16,119.2,796.46z'
                />
                <path
                  id='XMLID_989_'
                  className='st0'
                  d='M119.37,538.51h256.92V419.72H119.37V267.97h256.92V101.88h-0.94   c-55.4,37.7-119.22,59.29-187.2,59.29c-68,0-131.82-21.59-187.21-59.29H0v666.79h119.37V538.51z'
                />
                <path
                  id='XMLID_988_'
                  className='st0'
                  d='M744.64,419.72H487.72v118.79h256.91v230.16H864V101.88h-0.93   c-55.4,37.7-119.22,59.29-187.21,59.29c-67.98,0-131.81-21.59-187.2-59.29h-0.94v166.09h256.91V419.72z'
                />
                <path
                  id='XMLID_987_'
                  className='st0'
                  d='m404.97 330.4h-109.98c-4.08-5.85-10.79-9.68-18.41-9.68-12.46 0-22.54 10.17-22.54 22.71 0 12.55 10.08 22.72 22.54 22.72 7.62 0 14.33-3.84 18.4-9.69h110v918.31l27.03 75.97 27.04-75.97v-918.32h109.99c4.08 5.85 10.79 9.69 18.43 9.69 12.43 0 22.52-10.17 22.52-22.72 0-12.54-10.09-22.71-22.52-22.71-7.64 0-14.35 3.83-18.43 9.68h-110v-268.08c6.19-6.62 10.04-15.45 10.04-25.23 0.01-20.48-16.59-37.08-37.06-37.08-20.49 0-37.08 16.6-37.08 37.08 0 9.78 3.85 18.61 10.04 25.23-0.01 37.71-0.01 143.68-0.01 268.09z'
                />
              </g>
            </svg>
            <span>${prices.face2face.price.toFixed(2)}</span>
          </div>
        </OutboundLink>
      )
    },
    /* mtgstocks: () => (
      <OutboundLink
        key={v4()}
        title='View on MTGStocks'
        url={prices.mtgstocks.url}
      >
        <div className={styles.price}>
          <span>📈${prices.mtgstocks.price.toFixed(2)}</span>    TODO: CA conversion
        </div>
      </OutboundLink>
    ), */
    manapool: () => (
      <OutboundLink
        key={v4()}
        title='Buy at Mana Pool'
        url={`https://manapool.com/card/${prices.manapool.slug}?ref=scm&tap_s=5258585-0b68aa&tm_medium=edhrec,cardprice`}
        utm_medium='affiliate'
      >
        <div className={styles.price}>
          <svg width='14' height='14' version='1.1' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'>
            <polygon
              transform='matrix(.122 0 0 .122 .064316 -.025459)'
              points='83.315 67.948 83.315 57.885 63.837 63.216 63.837 73.28'
            />
            <path d='m8.1912 0-7.1912 1.9626v9.636l4.7852 2.4013 7.3737-2.0227 8e-6 -9.636zm3.5092 9.5177-3.5887 1.0043v1.4176l-1.8054 0.47208v-7.7871l2.697 1.0006 2.6971-2.4769z' />
          </svg>
          <span>${prices.manapool.price.toFixed(2)}</span>
        </div>
      </OutboundLink>
    ),
    scg: () => (
      <OutboundLink
        key={v4()}
        title='Buy at Star City Games'
        url={`https://starcitygames.com/${prices.scg.slug}/?aff=6`}
        utm_medium='affiliate'
      >
        <div className={styles.price}>
          <svg width='16' height='14' version='1.1' viewBox='0 0 15 14'>
            <path d='M 7.412973,11.004101 3.14967,13.97079 C 3.025819,14.057072 2.862648,13.938475 2.906425,13.794088 L 4.410465,8.8227021 C 4.429725,8.7590251 4.407265,8.6899365 4.354275,8.6498203 L 0.2153,5.5116816 C 0.094951,5.420465 0.15735,5.2287986 0.30827,5.2256148 L 5.50125,5.1197525 C 5.56779,5.1183225 5.626534,5.0758155 5.648343,5.0129351 L 7.3536,0.1069767 c 0.04951,-0.1426355 0.251204,-0.1426355 0.300713,0 L 9.35973,5.0130943 c 0.02181,0.062881 0.08055,0.1055439 0.147092,0.1068174 l 5.192981,0.1058623 c 0.150913,0.00302 0.213157,0.1948501 0.09297,0.2860668 l -4.138975,3.1379795 c -0.05301,0.040275 -0.07546,0.1092053 -0.05619,0.1728818 l 1.50404,4.9713859 c 0.04378,0.144387 -0.119393,0.262984 -0.243244,0.176702 l -4.26331,-2.966689 c -0.0546,-0.03805 -0.127194,-0.03805 -0.181797,0 z' />
          </svg>
          <span>${prices.scg.price}</span>
        </div>
      </OutboundLink>
    ),
    tcgl: () => (
      <OutboundLink
        key={v4()}
        title='Buy at TCG Land'
        url={`https://www.tcg.land/product/mtg_single_${prices.tcgl.slug}?via=cardshops-edhrec-com`}
        utm_medium='affiliate'
      >
        <div className={styles.price}>
          <svg width='10' height='14' version='1.1' viewBox='0 0 10 14'>
            <g transform='matrix(.032377 0 0 .032377 -6.0205 -24.2)'>
              <path d='m494.63 901.83c0 85.3-154.4 278-154.4 278s-154.4-192.7-154.4-278 69.1-154.4 154.4-154.4 154.4 69.1 154.4 154.4z' />
            </g>
          </svg>
          <span>${prices.tcgl.price} MXN</span>
        </div>
      </OutboundLink>
    ),
    tcgplayer: () => {
      const end = tcgplayerKickback ? new Date(tcgplayerKickback.end) : new Date()
      const saleEndTime = `${end.getHours() % 12}:${end.getMinutes().toString().padStart(2, '0')} ${
        end.getHours() > 11 ? 'P' : 'A'
      }M ${end.toLocaleDateString()}`
      return (
        <TCGPContainer key={v4()} saleEndTime={saleEndTime} tcgplayerSale={tcgplayerSale}>
          <a
            href={tcgplayerLink({ subId1: 'edhrec,cardprice', u: prices.tcgplayer.url.split('?')[0] })}
            rel='noopener noreferrer'
            target='_blank'
          >
            <div className={classNames(styles.price, { [styles.sale]: tcgplayerSale > 0 })}>
              <svg width='16' height='12' viewBox='0 0 16 12' version='1.1' id='svg708'>
                <defs id='defs712' />
                <path
                  d='m 4.4262864,0.01228709 7.1480526,0.01922219 c 0.0064,-0.01273365 0.15313,0.02238514 0.275116,0.07494309 0.122714,0.0528822 0.220611,0.12328284 0.214203,0.1360977 0.02579,0 0.100493,0.1138744 0.168785,0.23172314 0.07316,0.12652712 0.138936,0.25767734 0.128635,0.25767734 L 12.399448,11.11334 c 0.01695,0 -0.03917,0.189548 -0.107467,0.341379 -0.04558,0.101628 -0.103817,0.186385 -0.122797,0.186385 0.0253,-0.0085 -0.02815,0.07414 -0.123769,0.154509 -0.08354,0.07015 -0.208445,0.138531 -0.221584,0.142911 l -7.3975386,0.03836 c -0.00697,0.021 -0.1695139,-0.02839 -0.3014752,-0.09538 C 4.0154788,11.826028 3.9311273,11.754411 3.936967,11.737054 3.9253688,11.742894 3.8226871,11.654568 3.7588558,11.555455 3.6823717,11.436795 3.644738,11.302644 3.6586885,11.295669 V 0.82790036 c -0.026765,0 0.016384,-0.13244793 0.093597,-0.26854573 0.058641,-0.10333048 0.1477773,-0.20876976 0.1779492,-0.2600294 0.039499,-0.0271709 0.096031,-0.087028 0.1827344,-0.14542513 0.124175,-0.08362147 0.283145,-0.1641608 0.3133173,-0.14161301 z M 7.381506,5.6347959 6.3356295,6.4407574 8.3889375,8.8970062 8.1106591,7.2082743 10.221472,5.7019526 9.3099903,4.7136621 11.132954,3.3416538 H 4.9348281 Z'
                  id='path702'
                />
                <path
                  d='M 3.0638487,1.0197187 0.59794852,1.422659 c -0.0100576,-0.00503 -0.15191368,0.069752 -0.25702858,0.1533736 -0.0922186,0.07324 -0.162052,0.1558879 -0.14729047,0.1632687 -0.0155723,0 -0.0908397,0.1008161 -0.1390177,0.2257211 -0.0498001,0.1292848 -0.06107399,0.2828206 -0.0515033,0.2828206 l 1.15139683,7.925222 c -0.013058,0 0.021007,0.14656 0.082404,0.26887 0.058965,0.117524 0.1449384,0.210878 0.157429,0.210878 -0.00876,0.0088 0.067968,0.09327 0.1676485,0.166513 0.1149287,0.08443 0.2567851,0.15743 0.2737365,0.140479 l 1.2281242,-0.09595 z'
                  id='path704'
                />
                <path
                  d='m 12.959162,1.0155011 2.427456,0.3454353 c 0.0093,-0.00462 0.122553,0.070239 0.218583,0.1493994 0.09944,0.08208 0.181276,0.1687839 0.165216,0.1768135 0.01249,0 0.08622,0.082404 0.142018,0.1927106 0.05824,0.1150099 0.09814,0.2582451 0.08508,0.2582451 l -1.157723,7.8101311 c 0.01784,0.01784 -0.156456,0.6014909 -0.163107,0.5948399 0.01727,0.0085 -0.05693,0.110062 -0.164323,0.202686 -0.102357,0.0884 -0.237239,0.168622 -0.25784,0.15232 l -1.295362,-0.06716 z'
                  id='path706'
                />
              </svg>
              <span>{`$${(((100 - tcgplayerSale) / 100) * prices.tcgplayer.price).toFixed(2)}`}</span>
            </div>
          </a>
        </TCGPContainer>
      )
    },
  }
  return (
    <div className={styles.prices}>
      {prices && (
        <>
          {[0, 1, 2].map((i) => {
            const priceSource = priceSources[i]
            return prices[priceSource] && priceLinks[priceSource] ? (
              (priceLinks[priceSource] as any)()
            ) : (
              <span key={v4()}>&nbsp;</span>
            )
          })}
        </>
      )}
    </div>
  )
}

export default CardPrices
